<template>
  <NoCompanyAttest v-if="$store.getters.userInfo.manage === 0" />
  <div v-else style="position: relative">
    <el-tabs v-model="activeName" :class="'listTabs ' + (activeName == 'facebook' ? 'facebook-ad-panel' : '')"
      @tab-click="tabClick">
      <el-tab-pane label="" name="facebook" v-if="rolePermission('FacebookDataPanel')">
        <template #label>
          <span id="firstStep">Facebook</span>
        </template>
        <FBDataPanel />
      </el-tab-pane>
      <el-tab-pane label="TikTok" name="tiktok" v-if="rolePermission('TikTokDataPanel')">
        <TTDataPanel />
      </el-tab-pane>
      <el-tab-pane label="微软广告" name="bing" v-if="rolePermission('BingDataPanel')">
        <Bing v-if="activeName === 'bing'" />
      </el-tab-pane>
      <el-tab-pane label="Google" name="Google" v-if="rolePermission('GoogleDataPanel')">
        <Google v-if="activeName === 'Google'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, getCurrentInstance } from 'vue'
import { getAccountPermission } from '@/request/api/business'
import NoCompanyAttest from "@/components/base/NoCompanyAttest";

const FBDataPanel = defineAsyncComponent(() =>
  import('./FBDataPanel.vue')
)

const TTDataPanel = defineAsyncComponent(() =>
  import('./TTDataPanel.vue')
)

const Bing = defineAsyncComponent(() =>
  import('@/views/ads/analysis/Bing')
)

const Google = defineAsyncComponent(() =>
  import('@/views/ads/analysis/Google')
)

const { proxy } = getCurrentInstance()
const activeName = ref('facebook')
const accountPermissionPage = ref([])

const tabClick = () => {
  if (activeName.value === 'facebook') {
    proxy.$bus.emit('getFBEchartsData')
  } else {
    proxy.$bus.emit('getTTEchartsData')
  }
}

const rolePermission = (node) => {
  if ([0, 1].includes(proxy.$store.getters.userInfo.manage)) return true
  const permission = accountPermissionPage.value
  let result = permission.includes(node)
  return result
}

onMounted(async () => {
  const res = await getAccountPermission()
  accountPermissionPage.value = res.data
  proxy.$store.commit('changeRolePermission', res.data)
  if (!res.data.includes('FacebookDataPanel')) {
    if (!res.data.includes('TikTokDataPanel')) {
      if (!res.data.includes('BingDataPanel')) {
        if (!res.data.includes('GoogleDataPanel')) {
          activeName.value = 'facebook'
        } else {
          activeName.value = 'google'
        }
      } else {
        activeName.value = 'bing'
      }
    } else {
      activeName.value = 'tiktok'
    }
  } else {
    activeName.value = 'facebook'
  }
})
</script>

<style lang="less" scoped>
.listTabs {
  :deep(.el-tabs__header) {
    background: #fff;

    .el-tabs__nav-wrap {
      padding-left: 20px;
    }
  }
}
</style>
