<template>
  <el-card class="box-card top-card" v-if="userInfo.manage !== 0">
    <template #header>
      <div class="card-header">
        <span class="SubHead">筛选条件</span>
        <!-- <el-button type="primary" size="small" @click="fbLogin">FB登录</el-button> -->
      </div>
    </template>
    <!-- 筛选条件 -->
    <el-row align="middle" class="cateRow">
      <span :style="{ marginRight: '8px' }" class="Text cateLabel">筛选条件：</span>
      <el-tag v-for="(cate, index) in mergeCateName" :key="index"><span>{{ cate }}</span></el-tag>
      <!-- <el-tag v-for="(cates, index) in cateObj" :key="index" closable @close="closeTag(cates.name, cates.id)">{{
        cates.name
      }}</el-tag> -->
      <el-button type="text" size="small" class="clearAll" @click="clearAll">恢复默认</el-button>
    </el-row>
    <!-- 行业类 -->
    <el-row align="middle" class="cateRow">
      <span :style="{ marginRight: '8px' }" class="Text cateLabel">行业：</span>
      <template v-for="tag in tradeTags" :key="tag.value">
        <el-check-tag :checked="selectedTradeTagsName.indexOf(tag.name) > -1"
          @change="checked => tradeChange(tag.name, tag.value, checked)" size="small">
          {{ tag.name }}
        </el-check-tag>
      </template>
    </el-row>
    <!-- 指标 -->
    <el-row align="middle" class="indexRow cateRow">
      <span :style="{ marginRight: '8px' }" class="Text cateLabel">指标：</span>
      <div class="indexRowFlex">
        <el-radio-group v-model="selectedIndexTags" size="small" @change="indexRadioChange">
          <el-radio-button label="CPC">
            CPC
            <el-tooltip effect="light" placement="top">
              <template #content>
                <span class="tagTips">每点击成本，广告每次点击的费用</span>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-radio-button>

          <el-radio-button label="CTR">
            CTR
            <el-tooltip effect="light" placement="top">
              <template #content>
                <span class="tagTips">点击率，广告的实际点击次数除以广告的展现量。</span>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-radio-button>

          <el-radio-button label="CPM">
            CPM
            <el-tooltip effect="light" placement="top">
              <template #content>
                <span class="tagTips">将广告展示给一千人的平均成本。</span>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </el-radio-button>

          <el-tooltip effect="light" placement="top" :disabled="isTooltip">
            <template #content>
              <span class="tagTips">该指标仅支持选择全球时查看</span>
            </template>
            <el-radio-button label="CPA" :disabled="this.selectedAreaTagsName.indexOf('全球') == -1">
              CPA
              <el-tooltip effect="light" placement="top">
                <template #content>
                  <span class="tagTips">单次行动成本,即访问者对你的广告所执行某一操作的平均成本，包括交易、注册或者广告点击等。其计算方法是将你的广告支出与所执行的操作总数相除。</span>
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-radio-button>
          </el-tooltip>

          <el-tooltip effect="light" placement="top" :disabled="isTooltip">
            <template #content>
              <span class="tagTips">该指标仅支持选择全球时查看</span>
            </template>
            <el-radio-button label="ROAS" :disabled="this.selectedAreaTagsName.indexOf('全球') == -1">
              ROAS
              <el-tooltip effect="light" placement="top">
                <template #content>
                  <span class="tagTips">投资回报率，展示从广告中获得的平均收益。</span>
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-radio-button>
          </el-tooltip>
        </el-radio-group>

        <div style="display:flex;">
          <el-checkbox v-model="compareValue" label="对比" size="small" @change="compareChangeCheck" />
          <el-select v-model="compareSelectValue" :disabled="compareSelectDisabled" size="small" class="compareSelect"
            @change="changeCompareIndex">
            <el-option v-for="item in indexCate" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled" />
          </el-select>
        </div>
      </div>
    </el-row>
    <!-- 营销大区 -->
    <el-row align="middle" class="cateRow">
      <span :style="{ marginRight: '8px' }" class="Text cateLabel">营销大区：</span>
      <template v-for="tag in areaTags" :key="tag.value">
        <el-check-tag :checked="selectedAreaTagsName.indexOf(tag.name) > -1"
          @change="checked => areaChange(tag.name, tag.id, checked)" size="small">
          {{ tag.name }}
        </el-check-tag>
      </template>
    </el-row>
    <!-- 品类筛选 -->
    <el-row class="cateRow">
      <span :style="{ marginRight: '8px', display: 'inline-block' }" class="Text cateLabel">品类筛选：</span>
      <div class="cateBox" ref="cateBoxRef">
        <template v-for="tag in allCateTags" :key="tag.value">
          <el-check-tag :checked="selectedAllCateTagsName.indexOf(tag.name) > -1"
            @change="checked => allCateChange(tag.name, tag.value, checked)" size="small">全部品类</el-check-tag>
        </template>
        <!-- <template v-for="tag in cateTags" :key="tag.id">
          <el-check-tag
            :checked="selectedCateTagsName.indexOf(tag.name) > -1"
            @change="checked => cateChange(tag.name, tag.id, checked)"
            size="small"
          >
            {{ tag.name }}
          </el-check-tag>
        </template>
        <el-button type="text" size="small" class="unfoldCateBtn" @click="unfoldCateBtn" v-show="isFold == 0"
          >更多<i class="el-icon-arrow-down"></i
        ></el-button>
        <el-button type="text" size="small" class="foldCateBtn" @click="foldCateBtn" v-show="isFold == 1"
          >收起<i class="el-icon-arrow-up"></i
        ></el-button> -->
      </div>
    </el-row>
  </el-card>

  <el-card class="box-card" style="margin-top:20px;" v-if="userInfo.manage !== 0">
    <template #header>
      <div class="card-header">
        <span class="SubHead">
          品类行业数据
          <el-tooltip class="item" effect="light" placement="top" style="max-width: 300px;">
            <template #content>
              展示筛选时间内行业品类数据的平均值。
            </template>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </span>
        <div>
          <el-date-picker v-model="cateTimeRange" type="daterange" size="small" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="X" unlink-panels :shortcuts="shortcuts" :disabled-date="disabledDate"
            @calendar-change="calendarChange" @blur="getEchartsData"></el-date-picker>
          <el-select class="sortCate" v-model="sortCate" placeholder="请选择排序方式" size="small" @change="getEchartsData">
            <el-option :label="sortOption" value="1"></el-option>
            <el-option label="按环比上升" value="2"></el-option>
          </el-select>
        </div>
      </div>
    </template>
    <template v-if="compareValue == false">
      <el-empty :image-size="300" v-if="cateCode == 100">
        <template #image>
          <img src="@/assets/empty.gif" />
        </template>
      </el-empty>
      <div id="cateChart" style="width: 100%;min-height: 100px;" v-else></div>
    </template>
    <template v-else>
      <div id="compareChart"></div>
    </template>
  </el-card>
  <NoCompanyAttest v-if="userInfo.manage === 0" />
</template>

<script>
import * as echarts from 'echarts'
import _ from 'lodash'
import { BidirectionalBar } from '@antv/g2plot'
import { auto } from '@popperjs/core'
import NoCompanyAttest from '../base/NoCompanyAttest.vue'
export default {
  name: 'AdCategory',
  components: {
    NoCompanyAttest
  },
  data() {
    return {
      userInfo: this.$store.getters.userInfo,
      isFold: 0,
      checked: false,
      cateCode: '',
      isTooltip: true,
      allTradeChecked: false,
      sortCate: '1',
      // 筛选条件
      cateList: [],
      // 行业类
      // tradeTags: ['全部', '电商', 'APP', '游戏'],
      // selectedTradeTags:['全部'],
      tradeTags: [
        {
          value: 1,
          name: '电商'
        }
      ],

      selectedTradeTagsName: ['电商'],
      selectedTradeTagsValue: [1],
      // 指标类
      selectedIndexTags: 'CPM',
      compareValue: false,
      compareSelectDisabled: true,
      compareSelectValue: 'CPA',
      indexCate: [
        {
          value: 'CPC',
          label: 'CPC',
          disabled: false
        },
        {
          value: 'CTR',
          label: 'CTR',
          disabled: false
        },
        {
          value: 'CPM',
          label: 'CPM',
          disabled: false
        },
        {
          value: 'CPA',
          label: 'CPA',
          disabled: false
        },
        {
          value: 'ROAS',
          label: 'ROAS',
          disabled: false
        }
      ],
      // 营销大区
      areaTags: [],
      selectedAreaTagsName: ['全球'],
      selectedAreaTagsValue: [1],
      // 品类筛选
      sendCate: '',
      allCateTags: [
        {
          value: 0,
          name: '全部品类'
        }
      ],
      selectedAllCateTagsName: ['全部品类'],
      selectedAllCateTagsValue: [0],
      cateTags: [],
      nextSelectedCateTagsName: [],
      selectedCateTagsName: [],
      nextSelectedCateTagsValue: [],
      selectedCateTagsValue: [],
      sortOption: '按CPM排序',
      // 图表数据
      cateTimeRange: '',
      startDate: null,
      shortcuts: [
        {
          text: '昨天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        },
        {
          text: '近7天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 7)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        },
        {
          text: '近30天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 30)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        },
        {
          text: '近90天',
          value: () => {
            const start = new Date()
            const end = new Date()
            start.setTime(start.getTime() - 8.64e7 * 90)
            end.setTime(end.getTime() - 8.64e7)
            return [start, end]
          }
        }
      ],
      // 图表数据
      cateAutoHeight: '',
      yFieldDate: [],
      data1: [],
      option: {
        title: {
          text: '',
          textStyle: {
            color: '#595959',
            fontSize: 12,
            fontWeight: 400
          },
          left: 40
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: params => {
            let list = []
            let listItem = ''
            let axisValueLabel = params[0].axisValueLabel
            for (let i = 0; i < params.length; i++) {
              list.push(
                `
                <span style="font-size:12px;">${params[i].data.name}:&nbsp;&nbsp;</span>
                <span style="font-size:12px;">${params[i].data.value1}</span><br/>
                <span style="font-size:12px;">${params[i].data.name1}:&nbsp;&nbsp;</span>
                <span style="font-size:12px;">${params[i].data.chain}</span>
                `
              )
            }
            listItem = list.join('<br>')
            return axisValueLabel + '<br>' + listItem
          }
        },
        legend: {
          x: 'left',
          textStyle: {
            color: '#595959',
            fontSize: 12
          },
          icon: 'circle',
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: '3%',
          right: '4%',
          // top: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            fontSize: '14',
            color: '#595959'
          },
          data: []
        },
        series: [
          {
            type: 'bar',
            data: [],
            barWidth: 20,
            color: ['#2b82ff'],
            label: {
              show: true,
              position: 'right',
              color: '#595959'
            }
          }
        ]
      }
    }
  },
  created() {
    if (this.cateTimeRange == '') {
      this.cateTimeRange = this.getLastDay()
    }
  },
  mounted() {
    this.getEchartsData()
    this.getCateDates()
  },
  computed: {
    mergeCateName() {
      return (this.cateListName = [
        ...this.selectedTradeTagsName,
        this.selectedIndexTags,
        ...this.selectedAreaTagsName,
        ...this.selectedAllCateTagsName
      ])
    },
    cateObj: {
      get() {
        this.cateResult = []
        if (this.selectedCateTagsValue != undefined) {
          for (let i = 0; i < this.selectedCateTagsValue.length; i++) {
            let obj = {}
            obj.id = this.selectedCateTagsValue[i]
            obj.name = this.selectedCateTagsName[i]
            this.cateResult.push(obj)
          }
          return this.cateResult
        }
      },
      set(value) {
        this.cateResult = value
      }
    }
  },
  methods: {
    async getEchartsData() {
      if (this.cateTimeRange == null) {
        return this.$message.warning('请选择时间范围')
      }
      // 品类筛选传值判断
      if (this.selectedCateTagsValue.length > 0) {
        this.sendCate = this.selectedCateTagsValue
      } else {
        this.sendCate = 0
      }

      var compareSelectValue = this.compareSelectValue
      if (this.compareValue == false) {
        compareSelectValue = ''
      }
      var selectedAreaTagsValue = this.selectedAreaTagsValue.join()
      if (this.userInfo.manage === 0) return
      const { data: res } = await this.$http.post('cloudfb/category_data', {
        time: this.cateTimeRange,
        index: this.selectedIndexTags,
        c_ids: this.sendCate,
        sort: this.sortCate,
        continent: selectedAreaTagsValue,
        compare: compareSelectValue
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.warning('此品类暂无数据')
      }
      this.cateCode = res.code
      this.$nextTick(() => {
        if (this.cateCode != 100) {
          if (this.compareValue == false) {
            document.getElementById('cateChart').removeAttribute('_echarts_instance_')
            var cateChart = echarts.init(document.getElementById('cateChart'))

            const result = _.merge(res.data.data, this.option)
            cateChart.setOption(result)

            this.cateAutoHeight = result.yAxis.data.length * 40 + 100 + 'px'
            cateChart.resize({ height: this.cateAutoHeight })
            window.onresize = () => {
              cateChart.resize()
            }
          } else {
            this.yFieldDate = res.ydata
            this.data1 = res.data
            this.$nextTick(() => {
              this.antChart()
            })
          }
        }
      })
    },
    antChart() {
      if (this.compareBarPlot) {
        this.compareBarPlot.destroy()
      }
      var data = this.data1
      this.compareBarPlot = new BidirectionalBar('compareChart', {
        data,
        xField: 'category',
        yField: this.yFieldDate,
        xAxis: {
          position: 'bottom',
          textStyle: {
            textAlign: 'center'
          },
          title: {
            text: '',
            spacing: 80
          },
          label: {
            style: {
              fill: '#595959',
              fontSize: 14
            },
            offsetX: 40
          },
          line: {
            style: {
              lineWidth: 0,
              strokeOpacity: 0
            }
          },
          tickLine: {
            style: {
              lineWidth: 0,
              strokeOpacity: 0
            }
          }
        },
        appendPadding: [0, 60],
        height: data.length * 40 + 100,
        legend: {
          position: 'top-left',
          marker: {
            style: style => {
              return {
                // 半径
                r: 4,
                // 样式类型
                symbol: 'circle',
                // 填充
                fill: style.fill || style.stroke,
                // 边框线宽
                lineWidth: 1,
                // 边框填充颜色
                stroke: style.fill || style.stroke,
                // 边框透明度
                strokeOpacity: 1,
                // 线圆角
                lineCap: 'round',
                lineJoin: 'round'
              }
            }
          }
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        // 开启 label 展示
        label: {
          // 默认居中, 'middle'
          position: 'right',
          // 默认为: 2
          offset: 10
        },
        interactions: [{ type: 'active-region' }],
        tooltip: {
          shared: true,
          showMarkers: true
          // itemTpl: `
          // <li class="g2-tooltip-list-item">
          //   <span class="g2-tooltip-marker" style="background-color: {color};"></span>
          //   <span class="g2-tooltip-name">{name}</span>:
          //   <span class="g2-tooltip-value">{value}</span>
          // </li>`
        }
      })
      this.compareBarPlot.update({
        theme: { styleSheet: { brandColor: '#595959', paletteQualitative10: ['#2B82FF', '#00f1a2'] } }
      })
      this.compareBarPlot.render()
    },
    async getCateDates() {
      const { data: res } = await this.$http.post('cloudfb/get_category')
      this.cateTags = res.data.category
      this.areaTags = res.data.continent
    },
    // 重置全部tag
    clearAll() {
      // 行业
      this.selectedTradeTagsName = ['电商']
      this.selectedTradeTagsValue = [1]
      // 指标
      this.selectedIndexTags = 'CPM'
      // 营销大区
      this.selectedAreaTagsName = ['全球']
      this.selectedAreaTagsValue = [1]
      // 1.品类筛选-全部分类
      this.selectedAllCateTagsName = ['全部品类']
      this.selectedAllCateTagsValue = [0]
      // 2.品类筛选-复选分类
      this.selectedCateTagsName = []
      this.selectedCateTagsValue = []
      this.compareValue = false
      this.compareSelectDisabled = true
      this.indexRadioChange()
      this.getEchartsData()
    },
    // 清除当前复选tag
    closeTag(name, id) {
      this.selectedCateTagsName.splice(this.selectedCateTagsName.indexOf(name), 1)
      this.selectedCateTagsValue.splice(this.selectedCateTagsValue.indexOf(id), 1)
      this.cateObj = this.cateObj.filter(t => t.name != name)
      if (this.selectedCateTagsName.length == 0) {
        this.selectedAllCateTagsName = ['全部品类']
        this.selectedAllCateTagsValue = [0]
      }
      this.getEchartsData()
    },
    changeCompareIndex() {
      this.getEchartsData()
    },
    indexRadioChange() {
      if (this.selectedIndexTags == 'CPC') {
        this.sortOption = '按CPC排序'

        this.compareSelectValue = 'CTR'
        this.isArea()
      } else if (this.selectedIndexTags == 'CTR') {
        this.sortOption = '按CTR排序'
        this.compareSelectValue = 'CPM'
        this.isArea()
      } else if (this.selectedIndexTags == 'CPM') {
        this.sortOption = '按CPM排序'
        if (this.selectedAreaTagsName.indexOf('全球') > -1) {
          this.compareSelectValue = 'CPA'
          this.indexCate.filter(item => {
            if (item.value == this.selectedIndexTags) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          })
        } else {
          this.compareSelectValue = 'CPC'
          this.indexCate.filter(item => {
            if (item.value == 'CPA' || item.value == 'ROAS' || item.value == this.selectedIndexTags) {
              item.disabled = true
            } else {
              item.disabled = false
            }
          })
        }
      } else if (this.selectedIndexTags == 'CPA' && this.selectedAreaTagsName.indexOf('全球') > -1) {
        this.sortOption = '按CPA排序'
        this.compareSelectValue = 'ROAS'
        this.isArea()
      } else if (this.selectedIndexTags == 'ROAS' && this.selectedAreaTagsName.indexOf('全球') > -1) {
        this.sortOption = '按ROAS排序'
        this.compareSelectValue = 'CPC'
        this.isArea()
      }
      this.getEchartsData()
    },
    // 行业-选择切换
    tradeChange(name, value, checked) {
      if (this.selectedTradeTagsName.join() == name) {
        return
      }
      this.selectedTradeTagsName = checked ? [name] : this.selectedTradeTagsName.filter(t => t !== name)
      this.selectedTradeTagsValue = checked ? [value] : this.selectedTradeTagsValue.filter(t => t !== value)
      this.getEchartsData()
    },
    // 判断全球还是其他区
    isArea() {
      if (this.selectedAreaTagsName.indexOf('全球') > -1) {
        this.indexCate.filter(item => {
          if (item.value == this.selectedIndexTags) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        })
      } else {
        this.indexCate.filter(item => {
          if (item.value == 'CPA' || item.value == 'ROAS' || item.value == this.selectedIndexTags) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        })
      }
    },
    // 营销大区-选择切换
    areaChange(name, id, checked) {
      // 自身不可切换选择
      if (this.selectedAreaTagsName.join() == name) {
        return
      }
      // 营销大区的名字
      this.selectedAreaTagsName = checked ? [name] : this.selectedAreaTagsName.filter(t => t !== name)
      // 营销大区的ID
      this.selectedAreaTagsValue = checked ? [id] : this.selectedAreaTagsValue.filter(t => t !== id)

      if (this.selectedAreaTagsName.indexOf('全球') > -1) {
        this.isTooltip = true

        this.indexCate.filter(item => {
          if (item.value == this.selectedIndexTags) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        })
      } else {
        this.isTooltip = false

        // 判断不是选择全球时，对比值为CPA和ROAS时，不可用
        if (this.selectedIndexTags == 'CPM') {
          this.compareSelectValue = 'CPC'
        } else if (this.selectedIndexTags == 'CPA' || this.selectedIndexTags == 'ROAS') {
          this.selectedIndexTags = 'CPM'
        }

        if (this.compareSelectValue == 'CPA' || this.compareSelectValue == 'ROAS') {
          if (this.selectedIndexTags == 'CPM') {
            this.compareSelectValue = 'CPC'
          }
        }
        this.indexCate.filter(item => {
          if (item.value == 'CPA' || item.value == 'ROAS' || item.value == this.selectedIndexTags) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        })
      }
      this.getEchartsData()
    },
    // 1.品类筛选-全部分类-选择切换
    allCateChange(name, value, checked) {
      if (this.selectedAllCateTagsName.join() == name) {
        return
      }
      this.selectedAllCateTagsName = checked ? [name] : this.selectedAllCateTagsName.filter(t => t !== name)
      this.selectedAllCateTagsValue = checked ? [value] : this.selectedAllCateTagsValue.filter(t => t !== value)
      if (this.selectedAllCateTagsName.length > 0) {
        this.selectedCateTagsName = []
        this.selectedCateTagsValue = []
        this.sendCate = 0
        this.getEchartsData()
      }
    },
    // 1.品类筛选-复选分类-选择切换
    cateChange(name, id, checked) {
      if (this.selectedCateTagsName.length >= 5) {
        if (this.selectedCateTagsName.indexOf(name) == -1) {
          return this.$message.warning('最多只可选择5项!')
        }
      }
      this.nextSelectedCateTagsName = checked
        ? [...this.selectedCateTagsName, name]
        : this.selectedCateTagsName.filter(t => t != name)
      this.selectedCateTagsName = this.nextSelectedCateTagsName

      this.nextSelectedCateTagsValue = checked
        ? [...this.selectedCateTagsValue, id]
        : this.selectedCateTagsValue.filter(t => t != id)
      this.selectedCateTagsValue = this.nextSelectedCateTagsValue
      this.getEchartsData()

      if (this.selectedCateTagsName.length > 0) {
        this.selectedAllCateTagsName = []
        this.selectedAllCateTagsValue = [0]
      } else {
        this.selectedAllCateTagsName = ['全部品类']
        this.selectedAllCateTagsValue = [0]
      }
    },
    // 默认显示昨天
    getLastDay() {
      const start = Math.round((new Date().getTime() - 8.64e7) / 1000)
      const end = Math.round((new Date().getTime() - 8.64e7) / 1000)
      return [start, end]
    },
    calendarChange(dates) {
      // 记录选择的开始日期，方便后面根据开始日期限定结束日期
      if (dates !== null && dates.length > 0) {
        this.startDate = dates[0]
      }
    },
    // 日期选择器禁止选择的条件
    disabledDate(time) {
      // 8.64e7 等于 3600 * 1000 * 24
      if (this.startDate !== null) {
        return (
          time.getTime() > Date.now() - 8.64e7 ||
          time.getTime() < 1640966400 * 1000 ||
          time.getTime() > this.startDate.getTime() + 180 * 8.64e7
        )
      }
      return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 8.64e7
    },
    unfoldCateBtn() {
      this.isFold = 1
      this.$refs.cateBoxRef.style.height = 'auto'
      this.$refs.cateBoxRef.style.overflow = 'inherit'
    },
    foldCateBtn() {
      this.isFold = 0
      this.$refs.cateBoxRef.style.height = '28px'
      this.$refs.cateBoxRef.style.overflow = 'hidden'
    },
    // 是否开启对比
    compareChangeCheck() {
      this.compareSelectDisabled = !this.compareSelectDisabled
      if (this.compareValue == true) {
        this.getEchartsData()
      } else {
        this.getEchartsData()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-card {
  .el-row.cateRow {
    margin-bottom: 20px;
    border-bottom: 1px dashed #e4e7ed;
    padding-bottom: 10px;
  }
}

.el-check-tag {
  font-weight: 400;
  color: #595959;
  background-color: #fff;
  margin-right: 10px;
}

.indexRow {
  .el-icon-warning-outline {
    font-size: 14px;
  }
}

.el-check-tag.is-checked {
  color: #fff;
  background-color: #2b82ff;
}

.el-tag {
  color: #fff;
  background-color: #2b82ff;
  margin-right: 10px;
  padding: 7px 15px;
  font-size: 14px;
  height: 30px;
  line-height: 14px;

  :deep(.el-tag__close) {
    color: #fff;
  }
}

.cateLabel {
  width: 75px;
}

.clearAll {
  color: #2b82ff;
}

.cateBox {
  position: relative;
  width: 1035px;
  height: 28px;
  overflow: hidden;

  .el-check-tag {
    margin-bottom: 10px;
  }
}

.unfoldCateBtn,
.foldCateBtn {
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 14px;
  color: #2b82ff;
}

.el-icon-arrow-down,
.el-icon-arrow-up {
  font-weight: 600;
}

.tagTips {
  display: inline-block;
  max-width: 300px;
}

.sortCate {
  width: 140px;
  margin-left: 10px;
}

.indexRowFlex {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.compareSelect {
  width: 120px;
  margin-left: 10px;
}

:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: #2b82ff;
}

:deep(.el-radio-button--small .el-radio-button__inner) {
  padding: 7px 15px;
}

@media screen and (max-width: 1440px) {
  .cateBox {
    width: 950px;
  }
}
</style>
